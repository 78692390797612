import React, { useState } from 'react';
import './devicereport.css';
import { accessories, benderror, bodydent, bodyscratches, functionalerror, panelerror, screenlineerror, screenphysicalcondition, screenspoterror, toucherr, warranty } from '../../utils/functions';

function DeviceReport({ selectedModel }) {
    // Hold rendering if selectedModel is not yet loaded
    if (!selectedModel) {
        return <p>Loading device report...</p>;
    }

    return (
        <>
            <p style={{ padding: '0 8px' }}><b>SCREEN CONDITION</b> </p>
            <table className="table">
                <tbody>
                    <tr>
                        <th>Screen Touch Calibration</th>
                        <td>{toucherr(selectedModel?.toucherr)}</td>
                    </tr>
                    <tr>
                        <th>Screen Display Spot</th>
                        <td>{screenspoterror(selectedModel?.screenspoterror)}</td>
                    </tr>
                    <tr>
                        <th>Screen Display lines</th>
                        <td>{screenlineerror(selectedModel?.screenlineerror)}</td>
                    </tr>
                    <tr>
                        <th>Screen Physical Condition</th>
                        <td>{screenphysicalcondition(selectedModel?.screenphysicalcondition)}</td>
                    </tr>
                </tbody>
            </table>
            <p style={{ padding: '0 8px' }}><b>DEVICE OVERALL CONDITION</b> </p>
            <table className="table">
                <tbody>
                    <tr>
                        <th>SIM (Network)</th>
                        <td>{selectedModel?.callingerr == 'yes' ? 'Working' : 'Not Working'}</td>
                    </tr>
                    <tr>
                        <th>Body Scratches</th>
                        <td>{bodyscratches(selectedModel?.bodyscratches)}</td>
                    </tr>
                    <tr>
                        <th>Body Dents</th>
                        <td>{bodydent(selectedModel?.bodydent)}</td>
                    </tr>
                    <tr>
                        <th>Panel Condition</th>
                        <td>{panelerror(selectedModel?.panelerror)}</td>
                    </tr>
                    <tr>
                        <th>Physical Bends</th>
                        <td>{benderror(selectedModel?.benderror)}</td>
                    </tr>
                </tbody>
            </table>

            <p style={{ padding: '0 8px' }}><b>MOBILE AGE</b> </p>
            <table className="table">
                <tbody>
                    <tr>
                        <th>Mobile Period	</th>
                        <td>{warranty(selectedModel?.warranty)}</td>
                    </tr>
                    <tr>
                        <th> Phone Waranty	</th>
                        <td>{selectedModel?.warranty == '' ? 'No Warranty' : 'Warranty'}</td>
                    </tr>
                </tbody>
            </table>

            <p style={{ padding: '0 8px' }}><b>FUNCTIONAL PROBLEMS </b> </p>
            <table className="table">
                <tbody>
                    <tr>
                        <th>Front Camera</th>
                        <td>{functionalerror(selectedModel?.functionalerror, 'F1')}</td>
                    </tr>
                    <tr>
                        <th>Back Camera</th>
                        <td>{functionalerror(selectedModel?.functionalerror, 'F2')}</td>
                    </tr>
                    <tr>
                        <th>WiFi</th>
                        <td>{functionalerror(selectedModel?.functionalerror, 'wifi')}</td>
                    </tr>
                    <tr>
                        <th>Volume Button	</th>
                        <td>{functionalerror(selectedModel?.functionalerror, 'Vbutton')}</td>
                    </tr>
                    <tr>
                        <th>Battery	</th>
                        <td>{functionalerror(selectedModel?.functionalerror, 'Battery')}</td>
                    </tr>
                    <tr>
                        <th>Speaker	</th>
                        <td>{functionalerror(selectedModel?.functionalerror, 'Spk')}</td>
                    </tr>
                    <tr>
                        <th>Power Button</th>
                        <td>{functionalerror(selectedModel?.functionalerror, 'PwBut')}</td>
                    </tr>
                    <tr>
                        <th>Audio Receiver</th>
                        <td>{functionalerror(selectedModel?.functionalerror, 'AudRcv')}</td>
                    </tr>
                    <tr>
                        <th>Charging port</th>
                        <td>{functionalerror(selectedModel?.functionalerror, 'chgPort')}</td>
                    </tr>
                    <tr>
                        <th>Finger touch sensor</th>
                        <td>{functionalerror(selectedModel?.functionalerror, 'FTouch')}</td>
                    </tr>
                    <tr>
                        <th>Face Sensor</th>
                        <td>{functionalerror(selectedModel?.functionalerror, 'FcSnsr')}</td>
                    </tr>
                </tbody>
            </table>
            <p style={{ padding: '0 8px' }}><b> ACCESSORIES </b> </p>
            <table className="table">
                <tbody>
                    <tr>
                        <th>Ear Phones	</th>
                        <td>{accessories(selectedModel?.accessories, 'EarPhone')}</td>
                    </tr>
                    <tr>
                        <th>Valid Bill</th>
                        <td>{selectedModel?.warranty == '' ? 'Not Available' : 'Available'}</td>
                    </tr>
                    <tr>
                        <th>Box</th>
                        <td>{accessories(selectedModel?.accessories, 'Box')}</td>
                    </tr>
                    <tr>
                        <th>Charger</th>
                        <td>{accessories(selectedModel?.accessories, 'Chr')}</td>
                    </tr>
                </tbody>
            </table>

        </>
    )
}

export default DeviceReport