export function toucherr(s) {
    if (s === 'yes') return 'Working';
    else if (s === 'no') return 'Not Working';
    return 'Working';
}

export function screenspoterror(s) {
    if (s === 'Sp1') return 'White / Black Spots';
    else if (s === 'Sp2') return 'Different Color Spots';
    else if (s === 'Sp3') return 'Yellow Spots';
    return 'No Defect';
}

export function screenlineerror(s) {
    if (s === 'SLi') return 'Single Line on Display';
    else if (s === 'SMLi') return 'Multi Lines on Display';
    return 'No Defect';
}

export function screenphysicalcondition(s) {
    if (s === 'Sbr1') return 'Front Glass Broken or Cracked';
    else if (s === 'Sbr2') return 'Chipped Cracked';
    else if (s === 'SHeavy') return 'Heavy Scratches';
    else if (s === 'SLight') return '1 or 2 Scratches';
    return 'No Defect';
}

export function bodyscratches(s) {
    if (s === 'B1') return '1-2 Scratches';
    else if (s === 'B2') return 'Major Scratches';
    return 'No Defect';
}

export function bodydent(s) {
    if (s === 'D1') return '1-2 Dents';
    else if (s === 'D2') return 'Heavy Dents and Cracked';
    return 'No Defect';
}

export function panelerror(s) {
    if (s === 'PanCr') return 'Cracked - Broken Side or Back Panel';
    else if (s === 'PanMis') return 'Missing Side or Back Panel';
    return 'No Defect';
}

export function benderror(s) {
    if (s === 'PanBent') return 'Body Bend or Deform';
    else if (s === 'PanLoose') return 'Loose Gap in Screen';
    return 'No Defect';
}

export function warranty(s) {
    if (s === 'warranty03') return '0-3 Months';
    else if (s === 'warranty36') return '3-6 Months';
    else if (s === 'warranty611') return '6-11 Months';
    else if (s === 'warrantyAbove11') return 'Above 11 Months';
    return 'No Warranty';
}

export function functionalerror(arr, value) {
    return arr?.includes(value) ? 'Not Working' : 'Working';
}

export function accessories(arr, value) {
    return arr?.includes(value) ? 'Available' : 'Not Available';
}